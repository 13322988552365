import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
// @ts-ignore
import * as style from './TopSellerReworked.module.scss';
import {faHeart, faTimes} from "@fortawesome/pro-light-svg-icons";
import {faHeart as hoverHeart} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Client, handleApiError} from "../../services/ApiService";
import {Link} from "gatsby";
import {connect, ConnectedProps} from "react-redux";
import {toast} from "react-toastify";
import Loading from "../Loading/Loading";
import {StaticImage} from "gatsby-plugin-image";
import {setBookmarkAmount} from "../../stores/bookmark/bookmarkSlice";

const mapState = ({member, auth, bookmark}) => ({
    member,
    auth,
    bookmark
})

const mapDispatch = {
    setBookmarkAmount,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type TopSellerProps = PropsFromRedux & {
    title?: string,
    background?: boolean,
    products: any
    showMoreButton?: boolean
}

const TopSellerReworked: FC<TopSellerProps> = ({
                                                   title,
                                                   background,
                                                   products,
                                                   member,
                                                   auth,
                                                   bookmark,
                                                   setBookmarkAmount,
                                                   showMoreButton
                                               }) => {
    const [allBookmarks, setAllBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false)
        console.log("TOPSELLER")
        console.log(products)
    }, [products.length])

    useEffect(() => {
        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)

            }).catch(handleApiError);
        }
    }, [member])

    function addBookmark(id: number, product) {
        console.log("add bookmarkt marketplace")
        Client.Bookmark.add(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt zur Merkliste hinzugefügt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count);

                console.log("help")

                //@ts-ignore
                if(window.dataLayer) {

                    let gme_add_to_wishlist = {
                        'event': 'add_to_wishlist',
                        'data': {
                            'currency': 'EUR',
                            'value': product.Price,
                            'items': [{
                                'item_id': product.SKU,
                                'item_name': product.Title,
                                'item_category': product.CategoryTitle,
                                'item_category2': product.CategoryTitle2,
                                'item_category3': product.CategoryTitle3,
                                'discount': product.Sale ? product.Price - product.RefPrice : 0,
                            }]
                        }
                    }

                    // @ts-ignore
                    window.dataLayer.push(gme_add_to_wishlist);

                    //@ts-ignore
                    window.dataLayer.push(basketDataLayer);

                    console.log(window.dataLayer)
                }

                let basketDataLayer = {
                    'cicData': {
                        'cic_product_title': product.Title,
                        'cic_product_category': product.CategoryTitle,
                        'cic_vendor_title': product.Vendor.Title,
                        'cic_product_price': product.Price
                    },
                    'event': 'fs.productFavorited'
                };
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)

            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    return (
        <>
            {loading ? <Loading type={"grow"} message={"Top-Produkte werden geladen."}/> :
                <div className={style.zIndex999 + ' position-relative d-block'}>
                    <>
                        {background ?
                            <div className={style.backgroundColor}>
                            </div>
                            : null
                        }
                    </>
                    <div className={style.container}>
                        <>
                            {title &&
                                <h2 className={style.heading}> {title} </h2>
                            }
                            <Row>
                                {products.length > 0 ?
                                    <>
                                        {products.map((item: any, index: number) =>
                                            <Col className={style.holder} lg={4} md={6} xs={6} key={index}>
                                                <div className={style.link}>
                                                    <div className={style.iconHolder}>
                                                        {item.Sale ? <span
                                                            className={"badge position-absolute translate-middle " + style.saleBadge}>Sale</span> : null}
                                                        <Link to={`/${item.CategoryURLTitle}/${item.URLTitle}`}>
                                                            {item.TeaserImage ?
                                                                <img src={item.TeaserImage} className={style.icon} loading={"lazy"}/>
                                                                :
                                                                <StaticImage
                                                                    src={'../../images/login-overlay-2x.png'}
                                                                    alt={'background'}
                                                                    placeholder="blurred"
                                                                    className={style.icon + ' img-fluid'} loading={"lazy"}/>

                                                            }
                                                        </Link>
                                                        {auth.Key ?
                                                            <>
                                                                <OverlayTrigger
                                                                    key={"top-topsell"}
                                                                    placement={"top"}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top-topsell`}>
                                                                            {allBookmarks ?
                                                                                <>
                                                                                    {(allBookmarks.find((bookmark) => {
                                                                                        return bookmark.ID === item.ID;
                                                                                    })) ?
                                                                                        <span> Von der Merkliste entfernen </span> :
                                                                                        <span> Zur Merkliste hinzufügen </span>} </> : null
                                                                            }

                                                                        </Tooltip>
                                                                    }

                                                                >
                                                                    <>
                                                                        {allBookmarks ?
                                                                            <>
                                                                                {(allBookmarks.find((bookmark) => {
                                                                                    return bookmark.ID === item.ID;
                                                                                })) ?
                                                                                    <span className={style.crossHolder}
                                                                                          onClick={() => removeBookmark(item.ID)}>
                                                                <FontAwesomeIcon icon={faTimes}
                                                                                 className={style.cross}/>
                                                            </span> :
                                                                                    <span className={style.heartHolder}
                                                                                          onClick={() => {
                                                                                              addBookmark(item.ID, item)
                                                                                          }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span>
                                                                                }
                                                                            </> :
                                                                            <>
                                                                                {member.MemberID ?

                                                                                    <span className={style.heartHolder}
                                                                                          onClick={() => {
                                                                                              addBookmark(item.ID, item)
                                                                                          }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span> : null
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                </OverlayTrigger>

                                                            </> : null

                                                        }
                                                    </div>

                                                </div>

                                                <h5 className={style.category}>
                                                    {item.CategoryTitle}
                                                </h5>
                                                <h3 className={style.name}>
                                                    <Link to={`/${item.CategoryURLTitle}/${item.URLTitle}`}>
                                                        {item.Title}
                                                    </Link>

                                                </h3>
                                                {item.Sale ?
                                                    <h3 className={style.price}>
                                                        <span
                                                            className={style.refPrice}>{item.RefNicePrice}</span> {item.NicePrice}
                                                    </h3>
                                                    : <h3 className={style.price}>
                                                        {item.NicePrice}
                                                    </h3>}


                                                <p className={style.mwst}>
                                                    inkl. MwSt.
                                                </p>

                                            </Col>
                                        )}
                                    </> :

                                    <Col lg={12} className={"d-flex justify-content-center"}>
                                        <h2> Keine Produkte gefunden </h2>
                                    </Col>
                                }
                            </Row>
                        </>
                    </div>
                </div>
            }
        </>
    )
}

export default connector(TopSellerReworked);
